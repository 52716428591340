import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/icons/Menu';
import Close from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import Button from 'components/CustomButtons/Button.js';
import MenuItems from './MenuItems';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import styles from './styles';
import TwitterIcon from '@material-ui/icons/Twitter';
import GitHubIcon from '@material-ui/icons/GitHub';
import TelegramIcon from '@material-ui/icons/Telegram';
import Link from '@material-ui/core/Link';
import discordIcon from "../../assets/img/social/discord_white.svg";
import { useTheme } from '@material-ui/core/styles';
import ConnectWallet from 'components/ConnectWallet/ConnectWallet';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import { currentChain, websiteUrl } from 'features/configure'
const useStyles = makeStyles(styles);

const DiscordIcon = () => {
  const theme = useTheme();
  return (
    <img src={require('../../assets/img/social/' + theme.palette.discordIcon)} style={{ width: 18, height: 18, marginTop: 0, verticalAlign: 'middle' }} alt='' />
  )
}


const Header = ({ topLinks }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [selectedChain, setSelectedChain] = React.useState("ETH");
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleChange = (event) => {
    // setSelectedChain(event.target.value);
    if (selectedChain == event.target.value)
      return
    window.location.href = websiteUrl[event.target.value]

    if (event.target.value == "Polygon") {
      window.location.href = "https://polygon.wasabix.finance/#/app";
    }

  };

  const renderBottomLinks = () => {

    return (
      <Grid container alignItems="center" style={{
        textAlign: 'center', position: "absolute", bottom: 0,
        borderTop: `1px solid ${theme.palette.background.grey}`,
        backgroundColor: theme.palette.background.menu, padding: 10,
        paddingTop: 0
      }}>
        <Grid item xs={7} style={{ margin: "5px 0" }}>
        </Grid>
        <Grid item xs={5} style={{ marginTop: 10 }}>
          <a href={"https://analytics.sushi.com/tokens/0x896e145568624a498c5a909187363ae947631503"}
            target="_blank"><Button color="info">Buy</Button></a>
        </Grid>
        <Grid item xs={3}>
          <a href="https://twitter.com/WasabixFinance" target="_blank" className={classes.itemLink}><TwitterIcon /></a>
        </Grid>
        <Grid item xs={3}>
          <a href="https://github.com/wasabi-swap-team" target="_blank" className={classes.itemLink}><GitHubIcon /></a>
        </Grid>
        <Grid item xs={3}>
          <a href="https://t.me/WasabixFinance" target="_blank" className={classes.itemLink}><TelegramIcon /></a>
        </Grid>
        <Grid item xs={3}>
          <a href="https://discord.gg/Wyd4t2qzHH" target="_blank" className={classes.itemLink}><DiscordIcon /></a>
        </Grid>

      </Grid>
    )
  }
  return (
    <>
      <AppBar className={`${classes.appBar} ${classes.dark}`} position="fixed">
      <Hidden mdUp>
        <Toolbar>
          <Grid
            justify="space-between"
            alignItems="center"
            container
            spacing={3}
          >
            <Grid item >
              <Hidden mdUp>
                <IconButton
                  className={classes.iconButton}
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                >
                  <Menu />
                </IconButton>

              </Hidden>
           
           

            </Grid>

            <Grid item >
              <Hidden xsDown>
                <ConnectWallet />
              </Hidden>
            </Grid>
          </Grid>


        </Toolbar>
</Hidden>
        <Hidden mdUp implementation="js">
          <Drawer
            variant="temporary"
            anchor={'left'}
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={handleDrawerToggle}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              className={classes.closeButtonDrawer}
            >
              <Close />
            </IconButton>

            <div className={classes.appResponsive}>{topLinks}</div>
            <div style={{ textAlign: 'center' }}>
              <ConnectWallet />
              <List><MenuItems /></List>
            </div>
          </Drawer>
        </Hidden>
      </AppBar>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.sideBarDrawerPaper, !open && classes.sideBarDrawerPaperClose),
          }}
          open={open}
        >
          <img src={require('assets/img/logo2.png').default} style={{height:100 ,objectFit:"contain", margin:20}}/>
          <List style={{ height: "100%" }}><MenuItems /></List>
        </Drawer>
      </Hidden>
    </>
  );
};

export default Header;
