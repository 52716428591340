import React, { useEffect,useState }  from "react";
import IconButton from '@material-ui/core/IconButton';

const UserInfo = ({logo=true,social=true}) => {

  return (
    <span style={{float:"right", marginTop:10}}>
        <IconButton>
          <img src={require("assets/img/mail.png").default} className="icon"/>
        </IconButton>
        <IconButton>
          <img src={require("assets/img/bells.png").default} className="icon"/>
        </IconButton>
        <IconButton>
          <img src={require("assets/img/user.png").default} className="icon"/>
          <span style={{marginLeft:8}}>
            <div style={{color:"#404040",fontSize:14,textAlign:"left"}}>James</div>
            <div className="label">Admin account</div>
        </span>
        </IconButton>
       
    </span>
    
  );
};

export default UserInfo;
