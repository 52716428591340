import React, { useEffect,useState }  from "react";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import Container from "@material-ui/core/Container";
import _ from "lodash"
import Tabs from "components/CustomTabs/Tabs.js";
import Social from 'features/home/components/Social';
import { default as PriceChart } from "./components/PriceChart";
import { useFetchPrice } from 'features/price/fetchPrice';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
const PricePage = () => {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const { fetchPrice, priceData } = useFetchPrice();
  const [tabValue, setTabValue] = React.useState(0);
  useEffect(() => {
      fetchPrice();

      const id = setInterval(() => {
        fetchPrice();
      }, 1000000);
      return () => clearInterval(id);
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
   const renderPrice = (img,header, num, ratio)=>{
    return(
      <div className="card" style={{background:"white", margin:0}}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={3}>
          <img src={require("assets/img/"+img).default} 
              style={{height:35,width:35,objectFit:"contain"}}/>
          </Grid>
          <Grid item xs={9} style={{textAlign:"right"}}>
          <div className="cardHeader" style={{marginBottom:3}}>${num}
            <span className="label" style={{paddingLeft:5}}>{header}</span>
          </div>
          <div>
          <img src={require("assets/img/"+(ratio>0?"up.png":"down.png")).default} 
              style={{height:12,width:22,objectFit:"contain"}}/>
            <span className={ratio>0?"cardGreenValue":"cardRedValue"} style={{paddingLeft:10}}>{parseFloat(ratio).toFixed(2)} %</span>
          </div>
          </Grid>
        </Grid>
   
      </div>
    )
  }
  const renderNews = (img,title, content, source, time, url)=>{
    return(
      <div className="card" style={{background:"white", margin:"10px 0"}}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={3}>
            <img src={img} 
              style={{height:120,width:125,objectFit:"cover",borderRadius:20}}/>
          </Grid>
          <Grid item xs={9} style={{textAlign:"left",padding:5}}>
          <div  style={{marginBottom:3, color:"#0B308E", fontSize:13, fontWeight:500}}>{source}
            <span className="label" style={{paddingLeft:10}}>{time}</span>
          </div>
          <div style={{color:"#404040",fontWeight:600, fontSize:14,marginBottom:10}}>
            <a href={url} target={"_blank"}>{title}</a>
          </div>
          <div className="label">
            {content}
          </div>
          </Grid>
        </Grid>
   
      </div>
    )
  }
  return (
    <div>
      <div className="title">Pricing</div>
      <div className="subTitle">Get summary of your weekly online transactions here.</div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <div className="card" >
             {priceData.btc && 
             <Tabs
             tabIndex={tabValue}
             handleChange={handleTabChange}
             tabs={[
               {
                 label: "BTC",
                 content: <PriceChart priceData={priceData.btc}/>,
               },
               {
                 label: "ETH",
                 content: <PriceChart priceData={priceData.eth}/>,
               },
               {
                label: "BNB",
                content: <PriceChart priceData={priceData.bnb}/>,
              },
             ]}
           />
             } 
          </div>
        </Grid>
        <Grid item xs={12} sm={5}>
          <div className="card" style={{backgroundColor:"#A4B5F4"}}>
            <div className="cardHeader" style={{color:"white"}}>Dashboard</div>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} >
                {renderPrice("btc.png","BTC",_.get(priceData,'btcToday.usd',"-"),_.get(priceData,'btcToday.usd_24h_change',0))}
              </Grid>
              <Grid item xs={12} sm={6} >
                {renderPrice("eth.png","ETH",_.get(priceData,'ethToday.usd',"-"),_.get(priceData,'ethToday.usd_24h_change',0))}
              </Grid>
              <Grid item xs={12} sm={6} >
                {renderPrice("bnb.png","BNB",_.get(priceData,'bnbToday.usd',"-"),_.get(priceData,'bnbToday.usd_24h_change',0))}
              </Grid>
              <Grid item xs={12} sm={6} >
                {renderPrice("btc.png","BTC",_.get(priceData,'btcToday.usd',"-"),_.get(priceData,'btcToday.usd_24h_change',0))}
              </Grid>
              <Grid item xs={12} sm={6} >
                {renderPrice("eth.png","ETH",_.get(priceData,'ethToday.usd',"-"),_.get(priceData,'ethToday.usd_24h_change',0))}
              </Grid>
              <Grid item xs={12} sm={6} >
                {renderPrice("bnb.png","BNB",_.get(priceData,'bnbToday.usd',"-"),_.get(priceData,'bnbToday.usd_24h_change',0))}
              </Grid>
     
            </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={7}>
          <div className="card" style={{height:345}}>
            <div className="cardHeader">Related News </div>
            {renderNews("https://venturebeat.com/wp-content/uploads/2021/05/axie.jpg?fit=750%2C374&strip=all","Hackers steal $620M in Ethereum and dollars from Axie Infinity maker Sky Mavis’ Ronin network",
            "Sky Mavis reported that the Ronin Network which supports its Axie Infinity game has been hacked and thieves stole 173,600 in Ethereum cryptocurrency (worth $594.6 million) and $25.5 million in U.S. dollars, stealing a total of $620 million.",
            "Venturebeat","4 HOURS AGO","https://venturebeat.com/2022/03/29/hackers-steal-620m-in-ethereum-and-dollars-in-axie-infinity-maker-sky-mavis-ronin-network/")}
             {renderNews("https://en-cdn.beincrypto.com/wp-content/uploads/2021/02/BIC_microstrategy_generic.jpg.optimal.jpg","MicroStrategy Draws $205M Bitcoin-Secured Loan to Buy More Bitcoin",
            "MacroStrategy, a subsidiary of @MicroStrategy, has closed a $205 million bitcoin-collateralized loan with Silvergate Bank to purchase #bitcoin",
            "Beincrypto","16 HOURS AGO","https://beincrypto.com/microstrategy-draws-205m-bitcoin-secured-loan-to-buy-more-bitcoin/")}
             {renderNews("https://cdn.decrypt.co/resize/1536/wp-content/uploads/2022/03/solana-opensea-nft-gID_4.png.webp","OpenSea Confirms It Will Start Listing Solana NFTs in April",
            "Collections like Solana Monkey Business, Degenerate Apes, and Aurory appear headed to OpenSea after the firm confirms recent leaks.",
            "Decrypt","20 HOURS AGO","https://decrypt.co/96334/opensea-solana-nfts-april")}
            </div>
    
          </Grid>
      </Grid>
    </div>
  );
};

export default PricePage;
