import React, { Component } from 'react';
import _ from "lodash";
import CanvasJSReact from './canvasjs.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
class DashboardChart extends Component {

	render() {
		const options = {
			animationEnabled: true,
            zoomEnabled: true,
			data:  [
                {
                  type: "splineArea",
                  color: "#648CF2",
                  markerSize: 0,
                  dataPoints: this.props.data
                }
                ]
		}
    const containerProps = {
      width: "90%",
      height: "280px",
      margin: "auto"
    };
		return (
		<div>
			<CanvasJSChart options = {options} containerProps={containerProps}
			/>
		</div>
		);
	}
}
export default DashboardChart;