import React, { Component } from 'react';
import _ from "lodash";
import CanvasJSReact from './canvasjs.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
class DashboardPieChart extends Component {
	render() {
		const options = {
			animationEnabled: true,
      subtitles: [{
				text: "$1,328,550",
				verticalAlign: "center",
				fontSize: 26,
        fontColor: "#0B308E",
				dockInsidePlotArea: true
			}],
			data: [{
				type: "doughnut",
				showInLegend: false,
				indexLabel: "",
				yValueFormatString: "#,###'%'",
				dataPoints: [
					{ name: "Class - A", y: 46.43, color: "#0B308E" },
					{ name: "Class - B", y: 53.57, color: "#F39D1A"},
		
				]
			}]
		}
    const containerProps = {
      width: "100%",
      height: "250px",
      margin: "auto"
    };
		return (
		<div>
			<CanvasJSChart options = {options} containerProps={containerProps}
			/>
		</div>
		);
	}
}
export default DashboardPieChart;