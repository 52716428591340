import React, { useEffect,useState }  from "react";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";

import Social from 'features/home/components/Social';
import DashboardChart from "./components/DashboardChart";
import DashboardPieChart from "./components/DashboardPieChart";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "components/CustomTabs/Tabs.js";
const Fund = () => {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
   const renderCard = (img,header, num)=>{
    return(
      <div className="card" style={{background:"white", margin:0,height:200,position:"relative"}}>
        <Grid container >
          <Grid item xs={12}>
          <img src={require("assets/img/"+img).default} 
              style={{height:40,width:40,objectFit:"contain"}}/>
          </Grid>
          <Grid item xs={12} >
          <div className="cardHeader" style={{margin:"20px 0",fontSize:14}}>
            {header}
          </div>
          <div>
            <span className={"cardLgValue"} style={{position:"absolute",bottom:30}}>{num}</span>
          </div>
          </Grid>
        </Grid>
   
      </div>
    )
  }
  const renderFundContent = (net,coin,type, data)=>{
    return(
      <div className="card" >
      <div className="cardHeader">Fund OverView</div>

      <Grid container spacing={2} style={{margin:"0 auto", maxWidth:800}}>
        <Grid item xs={12} sm={3}>
          {renderCard("coin.png","Base Currency for Fund",coin)}
        </Grid>
        <Grid item xs={12} sm={3}>
          {renderCard("asset.png","Total Net Assets(USDT)",net)}
        </Grid>
        <Grid item xs={12} sm={3}>
          {renderCard("calandar.png","Fund inception Date","1.6.2021")}
        </Grid>
        <Grid item xs={12} sm={3}>
          {renderCard("currency.png","Investment Style",type)}
        </Grid>
      </Grid>
      <div className="cardHeader">Balance</div>
      <div style={{height:400}}><DashboardChart data={data}/></div>
    </div>
  
    )
  }
  return (
    <div>
      <div className="title">Fund</div>
      <div className="subTitle">Get summary of your weekly online transactions here.</div>
      <Tabs
             tabIndex={tabValue}
             handleChange={handleTabChange}
             tabs={[
               {
                 label: "Class - A",
                 content: renderFundContent("$ 6,168,450.76","USDT","Stable Fund", [
                  { x: new Date(2021, 5, 1), y: 5467621.436 },
                  { x: new Date(2021, 6, 1), y: 5522849.935 },
                  { x: new Date(2021, 7, 1), y: 5578636.298 },
                  { x: new Date(2021, 8, 1), y: 5634986.16 },
                  { x: new Date(2021, 9, 1), y: 5691905.212 },
                  { x: new Date(2021, 10, 1), y: 5749399.204},
                  { x: new Date(2021, 11, 1), y: 5807473.943 },
                  { x: new Date(2022, 0, 1), y: 5866135.296 },
                  { x: new Date(2022, 1, 1), y: 5925389.188},
                  { x: new Date(2022, 2, 1), y: 5985241.604 }
                  ]),
               },
               {
                 label: "Class - B",
                 content: renderFundContent("$ 7,117,040.24","BTC", "Token Fund",[
                  { x: new Date(2021, 5, 1), y: 5357000 },
                  { x: new Date(2021, 6, 1), y: 5494130.92 },
                  { x: new Date(2021, 7, 1), y: 5580940.66 },
                  { x: new Date(2021, 8, 1), y: 5647910.794 },
                  { x: new Date(2021, 9, 1), y: 5732630.67},
                  { x: new Date(2021, 10, 1), y: 5818620.34 },
                  { x: new Date(2021, 11, 1), y: 5896590.582 },
                  { x: new Date(2022, 0, 1), y: 5855310.885 },
                  { x: new Date(2022, 1, 1), y: 6499400.1},
                  { x: new Date(2022, 2, 1), y: 7117040.2   }
                  ]),
               },
             ]}
           />
         
    </div>
  );
};

export default Fund;
