import React, { useEffect,useState }  from "react";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import Social from 'features/home/components/Social';
import DashboardChart from "./components/DashboardChart";
import { default as DashboardPieChart } from "./components/DashboardPieChart";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
const Intro = () => {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const currentBalance = 1328550
  const initBalance = 1000000
  const varity = (currentBalance-initBalance)/initBalance
   const renderCard = (img,header, title, content)=>{
    return(
      <div style={{
        background: "rgba(0, 0, 0, 0.25)",
        border: "1px solid rgba(150, 132, 90, 0.6)",
        boxSizing: "border-box",
        backdropFilter: "blur(7px)",
        borderRadius: "180.5px 180.5px 0px 0px",
        minHeight:700,
        margin:20,
        padding:30
      }}>
        <img src={require("assets/img/"+img).default} 
        style={{height:310,width:"100%",objectFit:"contain",borderRadius:50, marginBottom:30}}/>
          <div className="cardHeader">{header} - <span className="cardColorContent">{title}</span></div>
          <div className="cardContent">{content}</div>
      </div>
    )
  }
  return (
    <div>
      <div className="title">Asset Infomation</div>
      <div className="subTitle">Get summary of your monthly online transactions here.</div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <div className="card">
            <div className="cardHeader">Fund Overview</div>
            <Grid container>
            <Grid item xs={12} sm={10}>
              <DashboardChart  data={[
                  { x: new Date(2021, 5, 1), y: 1000000 },
                  { x: new Date(2021, 6, 1), y: 1025600 },
                  { x: new Date(2021, 7, 1), y: 1041804.48 },
                  { x: new Date(2021, 8, 1), y: 1054306.134 },
                  { x: new Date(2021, 9, 1), y: 1070120.726 },
                  { x: new Date(2021, 10, 1), y: 1086172.537 },
                  { x: new Date(2021, 11, 1), y: 1100727.249 },
                  { x: new Date(2022, 0, 1), y: 1093022.158 },
                  { x: new Date(2022, 1, 1), y: 1213254.595 },
                  { x: new Date(2022, 2, 1), y: 1328550 }
                  ]}/>
            </Grid>
            <Grid item xs={12} sm={2}>
              <div className="card" style={{textAlign:"right"}}>
                <div className="cardContent">Current balance</div>
                <div className="cardLgValue">$ {new BigNumber(currentBalance).toFormat(0)}</div>
                <hr/>
                <div className="cardContent">Netincome</div>
                <div className="cardGreenValue">$ {new BigNumber(currentBalance-initBalance).toFormat(0)}</div>
                <hr/>
                <div className="cardContent">Varity</div>
                <div className=""><span className={varity >=0?"cardGreenValue":"cardRedValue"}>{parseFloat(varity*100).toFixed(1)}%</span></div>
                <hr/>
                <div className="cardContent">initial capital</div>
                <div className="cardRedValue">$ {new BigNumber(initBalance).toFormat(0)}</div>
              </div>
            </Grid>
           </Grid>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="card" style={{minHeight:350}}>
            <div className="cardHeader">Current Stragety Chart</div>
            <Grid container>
            <Grid item xs={12} sm={6} style={{position:"relative"}}>
              <DashboardPieChart/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{margin:"40px 10px"}}>
                <div className="cardSubHeader">Class - A</div>
                <div style={{backgroundColor:"#0B308E",height:5,borderRadius:5, margin:"5px 0"}}></div>
                <span className="cardValue">$ 616,845.76</span>
                <div className="cardLgValue" style={{float:"right"}}>46.43%</div>
              </div>
              <div style={{margin:"40px 10px"}}>
                <div className="cardSubHeader">Class - B</div>
                <div style={{backgroundColor:"#F39D1A",height:5,borderRadius:5, margin:"5px 0"}}></div>
                <span className="cardValue">$ 711,704.24</span>
                <div className="cardLgValue" style={{float:"right"}}>53.57%</div>
              </div>
            </Grid>
            </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
          <div className="card">
            <div className="cardHeader">Inital Asset Management </div>
            <Grid container>
            <Grid item xs={12} sm={6}>
              <div style={{margin:"20px 10px"}}>
                <div className="cardSubHeader">Class - A</div>
                <div style={{backgroundColor:"#0B308E",height:3,borderRadius:5, margin:"5px 0"}}></div>
                <span className="cardSubHeader">$ 464,300</span>
                <div className="label" style={{float:"right"}}>46.43%</div>
              </div>
             
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{margin:"20px 10px"}}>
                <div className="cardSubHeader">Class - B</div>
                <div style={{backgroundColor:"#F39D1A",height:3,borderRadius:5, margin:"5px 0"}}></div>
                <span className="cardSubHeader">$ 535,700</span>
                <div className="label" style={{float:"right"}}>53.57%</div>
              </div>
             
            </Grid>
            </Grid>
            </div>
          <div className="card">
            <div className="cardHeader">Asset Admin</div>
            <Grid container alignItems="flex-end">
            <Grid item xs={12} sm={5}>
              <Button color="secondary">Contact Advisor<KeyboardArrowRightIcon/></Button>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Grid container style={{textAlign:"center"}}>
                <Grid item xs={12} sm={4}>
                  <img src={require("assets/img/james.png").default}/><br/>
                  <div style={{padding:5}}>James</div>
                </Grid>
                
              </Grid>
              
            </Grid>
            </Grid>
          
            </div>
          </Grid>
      </Grid>
    </div>
  );
};

export default Intro;
